// src/ComingSoon.js

import React from 'react';
import './ComingSoon.css';
import logo from './blue_flame.png'; // Make sure to add your logo image in the src folder

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <img src={logo} alt="Logo" className="logo" />
      <h1>blueforge is warming up!</h1>
      <p></p>
      <p>We're forging a powerful new way to interact with your databases. Stay tuned!</p>
    </div>
  );
};

export default ComingSoon;